import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import Banner from "../../Components/Banner/Banner";
import Featured from "../../Components/Featured/Featured";
import Policy from "../../Components/Policy/Policy";
import MostFavorite from "../../Components/MostFavorite/MostFavorite";
import SummerMakeup from "../../Components/SummerMakeup/SummerMakeup";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import InstaShop from "../../Components/InstaShop";
import Stores from "../../Components/Stores";
import { Helmet } from "react-helmet-async";
import ogIcon from '../../Images/ogIcon.ico';
import TopMakeupBrand from "../../Components/TopMakeupBrand/TopMakeupBrand";
import HomeProductImage from "../../Components/HomeProductImage/HomeProductImage";

dayjs.extend(timezone);

const Home = () => {
  const { loading } = useContext(ThemeContext);
  // const [showModal, setShowModal] = useState(true);
  
  // const currentDate = dayjs().tz("Asia/Dhaka");
  // const offerEndTime = dayjs.tz("2024-11-18 23:59:00", "Asia/Dhaka");
  // const [timeLeft, setTimeLeft] = useState(offerEndTime.diff(currentDate, 'second'));

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     const remainingTime = offerEndTime.diff(dayjs().tz("Asia/Dhaka"), "second");
  //     setTimeLeft(remainingTime > 0 ? remainingTime : 0);
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, [offerEndTime]);

  // useEffect(() => {
  //   if (showModal && currentDate.isBefore(offerEndTime) ) {
  //     document.documentElement.classList.add("overflow-hidden");
  //   } else {
  //     document.documentElement.classList.remove("overflow-hidden");
  //     setShowModal(false)
  //   }
  //   return () => document.documentElement.classList.remove("overflow-hidden");
  // }, [showModal,offerEndTime]);

  // const formatTime = (time) => {
  //   const hours = Math.floor(time / 3600);
  //   const minutes = Math.floor((time % 3600) / 60);
  //   const seconds = time % 60;
  //   return `${hours}h ${minutes}m ${seconds}s`;
  // };

  return (
    <div className="home">
      <Helmet>
        <title>Flormar Bangladesh- Top Makeup Brands In Bangladesh</title>
        <meta
          name="description"
          content="Flormar Bangladesh, Top Makeup Brands In Bangladesh! Explore The Best Cosmetics At The Best Price For Face, Eyes, Lips, Nails And Accessories. Discover Makeup That Enhances Your Natural Beauty."
        />
        <link rel="canonical" href="https://flormarbd.com" />
        <meta property="og:title" content="Flormar Bangladesh" />
        <meta property="og:description" content="Welcome to Flormar Bangladesh, your beauty destination! Explore cosmetics for face, eyes, lips, nails, and accessories. Discover makeup that enhances your natural beauty." />
        <meta property="og:image" content={ogIcon}/>
        <meta property="og:url" content="https://flormarbd.com"/>
        <meta property="og:type" content="website" />
        <meta name="robots" content="INDEX, FOLLOW" />
      </Helmet>

      {/* Tailwind Modal for Offer Closing Alert */}
      {/* {(showModal && currentDate.isBefore(offerEndTime) )&& (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 text-center max-w-sm w-full mx-4">
            <h2 className="text-xl font-bold mb-4 text-red-600">Hurry! Our Biggest Offer Is Closing Soon!</h2>
            <p className="text-gray-700 mb-6 text-3xl">{formatTime(timeLeft)}</p>
            <button
              onClick={() => setShowModal(false)}
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )} */}

      <div>
        <Banner />
        <Featured />
        <TopMakeupBrand />
        <SummerMakeup />
        <HomeProductImage />
        <MostFavorite />
        <InstaShop />
        <Policy />
        <Stores />
      </div>
    </div>
  );
};

export default Home;
