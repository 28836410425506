import React, { useContext, useEffect, useRef, useState } from "react";
import "./CartPage.css";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CartProduct from "../../Components/CartProduct/CartProduct";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import CartModal from "../../Components/CartModal/CartModal";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

const CartPage = () => {
  const { cart } = useContext(ThemeContext);
  const { orderList, bridalDiscount, setBridalDiscount } =
    useContext(ThemeContext);
  const [user] = useAuthState(auth);
  const [newCustomer, setNewCustomer] = useState(false);
  const cartModalRef = useRef();
  const [disabled, setDisabled] = useState(false);
  const [couponText, setCouponText] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [couponTotal, setCouponTotal] = useState(0);
  const { appliedCoupon, setAppliedCoupon } = useContext(ThemeContext);
  const { freeProduct, setFreeProduct } = useContext(ThemeContext);
  const { studentId, setStudentId } = useContext(ThemeContext);
  const [isOfferActive, setIsOfferActive] = useState(false);

  const previousCustomer = orderList?.find(
    (list) => list?.email === user?.email
  );
  useEffect(() => {
    setAppliedCoupon("");
    if (freeProduct.length > 0) {
      window.location.reload();
    }
    const viewItemListData = [];

    cart.forEach((product) => {
      const color = product?.variations?.find(
        (v) => v?.product_slug === product?.slug
      )?.color;
      viewItemListData.push({
        item_id: product._id,
        item_name: product.name,
        item_category: product.categories[0]?.name || "",
        item_category2: product.categories[1]?.name || "",
        item_category3: product.categories[2]?.name || "",
        price: product.regular_price,
        quantity: product.quantity,
        item_variant: color ? color : "",
      });
    });
    // Push the view_item_list event data to the DataLayer
    window.dataLayer.push({
      event: "view_cart",
      ecommerce: {
        currency: "BDT",
        value: price,
        items: viewItemListData,
      },
    });
    // Get current time in Bangladesh (Asia/Dhaka)
    const now = dayjs().tz("Asia/Dhaka");

    // Set the activation date and time: October 3, 2024, at 11:59 PM
    const offerActivationTime = dayjs.tz("2024-10-03 23:59:00", "Asia/Dhaka");
    const offerEndTime = dayjs.tz("2024-10-30 23:59:00", "Asia/Dhaka");

    // Check if the current time is past October 3, 2024, 11:59 PM
    if (now.isAfter(offerActivationTime) && now.isBefore(offerEndTime)) {
      setIsOfferActive(true);
    } else {
      setIsOfferActive(false);
    }
  }, []);

  useEffect(() => {
    if (previousCustomer) {
      setNewCustomer(false);
    } else {
      setNewCustomer(true);
    }
    fetch(`https://flormar-web-server-nayem55-flormar-bd.vercel.app/getCoupons`)
      .then((res) => res.json())
      .then((data) => setCoupons(data));
  }, [previousCustomer]);

  const handleModalOpen = () => {
    cartModalRef.current.showModal();
    document.documentElement.style.overflowY = "hidden";
  };

  const handleModalClose = () => {
    cartModalRef.current.close();
    setFreeProduct([]);
    document.documentElement.style.overflowY = "visible";
    setTimeout(() => window.location.reload(), 10);
  };

  let price = 0;
  let quantity = 0;
  let bridalProductPrice = 0;
  let productWithoutOffer = 0;

  cart?.forEach((product) => {
    quantity = quantity + product.quantity;
    price =
      price +
      (product?.on_sale > 0 ? product?.sale_price : product?.regular_price) *
        product.quantity;
    if (product?.on_sale > 0) {
      productWithoutOffer = productWithoutOffer + 0;
    } else {
      productWithoutOffer =
        productWithoutOffer + product?.regular_price * product?.quantity;
    }
    if (product?.on_sale > 0) {
      bridalProductPrice = bridalProductPrice + 0;
    } else {
      bridalProductPrice =
        bridalProductPrice + product?.regular_price * product.quantity;
    }
  });
  const voucherAmount =
    productWithoutOffer >= 1000 && productWithoutOffer < 2000
      ? 200
      : productWithoutOffer >= 2000 && productWithoutOffer < 3000
      ? 500
      : productWithoutOffer >= 3000 && productWithoutOffer < 5000
      ? 1000
      : productWithoutOffer >= 5000 && productWithoutOffer < 10000
      ? 2000
      : productWithoutOffer >= 10000 && productWithoutOffer < 20000
      ? 5000
      : productWithoutOffer >= 20000
      ? 20000
      : 0;
  useEffect(() => {
    if (bridalProductPrice >= 12500 && bridalProductPrice < 16000) {
      setBridalDiscount(price - bridalProductPrice * 0.2);
      localStorage.setItem("bridalDiscount", price - bridalProductPrice * 0.2);
    } else if (bridalProductPrice >= 16000 && bridalProductPrice < 20000) {
      setBridalDiscount(price - bridalProductPrice * 0.25);
      localStorage.setItem("bridalDiscount", price - bridalProductPrice * 0.25);
    } else if (bridalProductPrice >= 20000) {
      setBridalDiscount(price - bridalProductPrice * 0.3);
      localStorage.setItem("bridalDiscount", price - bridalProductPrice * 0.3);
    } else {
      setBridalDiscount(price);
      localStorage.setItem("bridalDiscount", price);
    }
  }, [bridalProductPrice, price]);

  const fullColorEnamel = cart.filter((item) =>
    item.name.toLowerCase().includes("full color")
  );
  const kajalOffer = cart.filter(
    (item) => item.name.toLowerCase() !== "just magic kajal liner flormar"
  );
  const onSale = cart.filter((item) => item?.on_sale == true);
  let fullColorEnamelQty = 0;
  fullColorEnamel?.forEach((product) => {
    fullColorEnamelQty = fullColorEnamelQty + product.quantity;
  });
  const maxiBrushEnamel = cart.filter((item) =>
    item.name.toLowerCase().includes("maxi brush")
  );
  let maxiBrushEnamelQty = 0;
  maxiBrushEnamel?.forEach((product) => {
    maxiBrushEnamelQty = maxiBrushEnamelQty + product.quantity;
  });

  const handleCoupon = () => {
    setCouponTotal(0);
    const appliedCoupon = coupons.find(
      // (coupon) => coupon.code.toLowerCase() === couponText.toLocaleLowerCase()
      (coupon) => coupon.code.toLowerCase() === "student"
    );
    const currentDate = new Date();
    const couponExpiryDate = new Date(appliedCoupon?.date_expires);
    if (appliedCoupon && currentDate <= couponExpiryDate) {
      if (appliedCoupon.discount_type === "percent") {
        setAppliedCoupon(appliedCoupon);
        price = price - Math.floor(price * (appliedCoupon.amount / 100));
        cart.forEach((item) => {
          item.price = Math.floor(
            item.price - item.price * (appliedCoupon.amount / 100)
          );
        });
        setCouponTotal(price);
        setCouponText("");
        // toast.success("Coupon added successfully");
        toast.success("Student offer applied");
        setDisabled(true);
      } else {
        setAppliedCoupon(appliedCoupon);
        price = price - appliedCoupon.amount;
        setCouponTotal(price);
        setCouponText("");
        toast.success("Coupon added successfully");
        setDisabled(true);
      }
    } else {
      setCouponText("");
      setAppliedCoupon("");
      toast.error("Invalid coupon");
    }
  };

  const submitImage = (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "flormar");
    data.append("cloud_name", "dkozpbeg3");

    fetch("https://api.cloudinary.com/v1_1/dkozpbeg3/image/upload", {
      method: "post",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        setStudentId(data.secure_url);
        handleCoupon();
      })
      .catch((err) => {
        console.log(err);
      });
    toast.success("Image added successfully");
  };

  const currentDate = dayjs().tz("Asia/Dhaka");
  const offerStartTime = dayjs.tz("2024-12-11 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2024-12-16 23:59:00", "Asia/Dhaka");

  const offerValidity =
    currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime);
    
  return (
    <div className="2xl:w-[65%] lg:w-[75%] w-[90%] mx-auto mb-10">
      <div className="my-10">
        <p className="text-[12px] font-semibold">
          Home
          <FontAwesomeIcon
            className="mx-2"
            icon={faCaretRight}
          ></FontAwesomeIcon>
          Shopping Cart
        </p>
        <div className="flex flex-col lg:flex-row justify-between">
          <p className="text-xl my-4 font-bold">My Cart</p>
          {voucherAmount && isOfferActive ? (
            <div className="bg-accent p-6 mb-6 font-bold">
              <p className="text-center text-xl sm:text-3xl text-white">
                Congratualations !!! You Got A {voucherAmount} TK. Voucher
              </p>
              <p className="text-center text-lg text-white sm:text-xl">
                You Will Receive The Voucher With Delivery.
              </p>
            </div>
          ) : (
            ""
          )}
          {/* {newCustomer ? (
            <p>
              Get <span className="text-2xl font-bold text-accent">10%</span>{" "}
              Discount On First Purchase
            </p>
          ) : (
            ""
          )} */}
          {bridalProductPrice >= 12500 && bridalProductPrice < 16000 ? (
            <p className="font-bold text-xl text-accent">
              Congratualations!! You Got 20% Discount
            </p>
          ) : bridalProductPrice >= 16000 && bridalProductPrice < 20000 ? (
            <p className="font-bold text-xl text-accent">
              Congratualations!! You Got 25% Discount
            </p>
          ) : (
            bridalProductPrice >= 20000 && (
              <p className="font-bold text-xl text-accent">
                Congratualations!! You Got 30% Discount
              </p>
            )
          )}
          <Link to="/" className="flex items-center">
            <p className="mr-2">Continue Shopping</p>
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Link>
        </div>
      </div>
      {/* .....................student offer ..................*/}
      {/* {
        onSale?.length>0?
        <div className="mb-10">
        <p className="font-bold text-accent">
          
        </p>
      </div>:
      <div className="mb-10">
        <p className="font-bold text-accent">
          JUST SHARE YOUR VALID STUDENT ID AND GET 30% OFF.
        </p>
        <input
          type="file"
          accept="image/*"
          className="my-4"
          onChange={(e) => submitImage(e.target.files[0])}
        />
      </div>
      } */}
      {/* .....................student offer ..................*/}
      <div className="cart-container flex flex-col lg:flex-row gap-[40px]">
        <div className="w-[100%] lg:w-[70%]">
          <div className="flex mb-4 justify-between bg-secondary bg-opacity-10 px-10 py-4">
            <p className="w-[85%]">Products</p>
            <p className="w-[15%]">Quantity</p>
          </div>
          {cart?.map((product) => (
            <CartProduct
              product={product}
              appliedCoupon={appliedCoupon}
            ></CartProduct>
          ))}

          {/* <div className="mt-6 flex items-center ">
            <input
              onChange={(e) => setCouponText(e.target.value)}
              className="border border-secondary px-4 h-[40px] rounded mr-4"
              placeholder="Coupon Code"
              type="text" 
            />
            <button
              disabled={disabled}
              onClick={handleCoupon}
              className={` border border-accent rounded px-4 h-[40px] ease-in-out duration-200 ${
                disabled
                  ? "bg-[#cccccc] text-accent"
                  : "bg-accent text-primary hover:border-secondary hover:bg-secondary"
              }`}
            >
              Apply
            </button>
          </div> */}
        </div>

        <div className="subtotal flex flex-col items-center w-[100%] lg:w-[30%]">
          <p className="block bg-secondary px-4 py-4 bg-opacity-10 w-full text-center">
            Subtotal
          </p>
          <div className="flex items-center gap-4">
            <p className="text-3xl my-6 text-accent font-bold">
              {/* TK. {newCustomer ? Math.floor(price - price * 0.1) : price} */}
              {/* TK. {couponTotal > 0 ? couponTotal : price} */}
              TK.{" "}
              {bridalProductPrice >= 12500 && bridalProductPrice < 16000
                ? price - bridalProductPrice * 0.2
                : bridalProductPrice >= 16000 && bridalProductPrice < 20000
                ? price - bridalProductPrice * 0.25
                : bridalProductPrice >= 20000
                ? price - bridalProductPrice * 0.3
                : couponTotal > 0
                ? couponTotal
                : price}
            </p>
            {bridalProductPrice >= 12500 ? (
              <s className="text-secondary text-opacity-70">
                <p className="text-xl text-secondary text-opacity-80">
                  TK. {price}
                </p>
              </s>
            ) : (
              ""
            )}
          </div>
          <hr className="h-[2px] bg-secondary bg-opacity-20 w-full" />
          <label htmlFor="comments" className="text-left w-full my-3 text-xs">
            {" "}
            Additional Comments
          </label>
          <textarea
            name="comments"
            rows="4"
            cols="20"
            className="border border-secondary w-full border-opacity-30"
          />
          {/* {quantity < 2 ? (
            <Link
              to="/shipping"
              className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
            >
              {" "}
              Proceed To Checkout{" "}
            </Link>
          ) : (
            <button
              onClick={() => {
                handleModalOpen();
              }}
              className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
            >
              {quantity === 2
                ? "Choose One Free Product"
                : "Choose Two Free Products"}
            </button>
          )} */}
          {/* {fullColorEnamelQty < 1 ? (
            maxiBrushEnamelQty > 0 && maxiBrushEnamelQty < 2 ? (
              <button
                onClick={() =>
                  toast.error(
                    "Minimum purchase quantity 2 for maxi brush nail enamel"
                  )
                }
                className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
              >
                {" "}
                Proceed To Checkout{" "}
              </button>
            ) : (
              <Link
                to="/shipping"
                className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
              >
                {" "}
                Proceed To Checkout{" "}
              </Link>
            )
          ) : (
            <button
              onClick={() => {
                handleModalOpen();
              }}
              className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
            >
              {fullColorEnamelQty === 1
                ? "Choose One Free Product"
                : `Choose ${fullColorEnamelQty || maxiBrushEnamelQty} Products`}
            </button>
          )} */}
          {/* full color minimum purchase 2 offer */}
          {fullColorEnamelQty > 0 && fullColorEnamelQty < 2 && !offerValidity? (
            <button
              onClick={() =>
                toast.error(
                  "Minimum purchase quantity 2 for full color nail enamel"
                )
              }
              className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
            >
              {" "}
              Proceed To Checkout{" "}
            </button>
          ) : (
            <Link
              to="/shipping"
              className="btn bg-secondary w-full mt-[15px] text-white hover:bg-secondary border-none rounded"
            >
              {" "}
              Proceed To Checkout{" "}
            </Link>
          )}
          {/* <Link
            to="/shipping"
            className="btn bg-secondary hover:bg-accent w-full mt-[15px] text-white border-none rounded"
          >
            {" "}
            Proceed To Checkout{" "}
          </Link> */}
        </div>
        <CartModal
          handleModalClose={handleModalClose}
          cartModalRef={cartModalRef}
        ></CartModal>
      </div>
    </div>
  );
};

export default CartPage;
