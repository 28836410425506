import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AllSlider = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    const fetchSliders = async () => {
      try {
        const response = await fetch(
          "https://flormar-web-server2.vercel.app/get-sliders"
        );

        if (!response.ok) {
          throw new Error(`Error fetching sliders: ${response.statusText}`);
        }

        const data = await response.json();
        setSliders(data);
      } catch (error) {
        console.error(error);
        alert("Failed to fetch sliders. Please try again later.");
      }
    };

    fetchSliders();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://flormar-web-server2.vercel.app/delete-slider/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error(`Error deleting slider: ${response.statusText}`);
      }

      const data = await response.json();
      alert("Slider deleted!");
      // Update local state
      setSliders(sliders.filter((slider) => slider._id !== id));
    } catch (error) {
      console.error(error);
      alert("Failed to delete slider. Please try again.");
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-4 my-12">
      <div className="flex gap-10 mb-12">
        <h1 className="text-2xl font-bold">All Sliders</h1>
        <Link
          to="/admin/add-slider"
          className="px-6 mr-6 border flex justify-center items-center border-accent bg-primary text-accent hover:bg-accent hover:text-primary ease-in-out duration-300"
        >
          Add New
        </Link>
      </div>
      <table className="min-w-full border-collapse border border-gray-300 text-center">
        <thead>
          <tr>
            <th className="border border-gray-300 p-2">Name</th>
            <th className="border border-gray-300 p-2">Order</th>
            <th className="border border-gray-300 p-2">Status</th>
            <th className="border border-gray-300 p-2">Link</th>
            <th className="border border-gray-300 p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sliders.map((slider) => (
            <tr key={slider._id}>
              <td className="border border-gray-300 p-2">{slider.name}</td>
              <td className="border border-gray-300 p-2">{slider.order}</td>
              <td className="border border-gray-300 p-2">{slider.status}</td>
              <td className="border border-gray-300 p-2">{slider.link}</td>
              <td className="border border-gray-300 p-2">
                <Link
                  to={`/admin/edit-slider/${slider._id}`}
                  className="text-blue-500"
                >
                  Edit
                </Link>
                <button
                  onClick={() => handleDelete(slider._id)}
                  className="text-red-500 ml-4"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllSlider;
