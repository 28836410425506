import React, { useContext, useRef, useState } from "react";
import "./Product.css";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { toast } from "react-hot-toast";
import { addToDb } from "../../utilities/CartDb";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import StarRating from "../Ratings";
import ReactPixel from "react-facebook-pixel";
import batch from "../../Images/bogo-batch.png";
import TagManager from "react-gtm-module";
import dayjs from "dayjs";

const Product = ({ product, list }) => {
  const { cart, setCart } = useContext(ThemeContext);
  const navigate = useNavigate();
  const dialogueRef = useRef();
  const [mouseHover, setMouseHover] = useState(false);

  const handleAddToCart = (item) => {
    let newCart = [];
    const exists = cart.find((product) => product._id == item._id);
    if (!exists) {
      item.quantity = 1;
      newCart = [...cart, item];
    } else {
      item.quantity = exists.quantity + 1;
      const rest = cart.filter((product) => product._id !== item._id);
      newCart = [...rest, item];
    }
    setCart(newCart);
    addToDb(item._id);

    toast.success("ADDED TO CART");
    // Track the 'AddToCart' event with any relevant parameters
    ReactPixel.track("AddToCart", {
      content_ids: item?._id,
      content_name: item?.name,
      content_type: "product",
      value:
        JSON.stringify(product?.on_sale) === "true"
          ? product?.sale_price
          : product?.regular_price,
      currency: "TAKA",
    });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "BDT",
        value: parseFloat(
          JSON.stringify(item?.on_sale) === "true"
            ? item?.sale_price
            : item?.regular_price
        ),
        items: [
          {
            item_id: item?._id,
            item_name: item?.name,
            item_category: item?.categories[0]?.name,
            item_category2: item?.categories[1]?.name,
            item_category3: item?.categories[2]?.name,
            price: parseFloat(
              JSON.stringify(item?.on_sale) === "true"
                ? item?.sale_price
                : item?.regular_price
            ),
            quantity: 1,
          },
        ],
      },
    });
  };

  // const handleModal = () => {
  //   dialogueRef.current.showModal();
  //   document.body.style.overflow = "hidden";
  // };

  // const handleBlurClose = () => {
  //   dialogueRef.current.close();
  //   document.body.style.overflow = "visible";
  // };

  const currentDate = dayjs().tz("Asia/Dhaka");

  const offerStartTime = dayjs.tz("2024-12-11 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2024-12-16 23:59:00", "Asia/Dhaka");

  // if (
  //   !(
  //     currentDate.isAfter(offerStartTime) &&
  //     currentDate.isBefore(offerEndTime) &&
  //     product.on_sale
  //   ) &&
  //   product?.sale_price === product?.regular_price * 0.5 &&
  //   !product.name.toLowerCase().includes("full color")
  // ) {
  //   product.on_sale = false;
  // }

  // if (currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime) && !(product.name.toLowerCase().includes("day cream") || product.name.toLowerCase().includes("(") )) {
  //   product.on_sale = true;
  //   product.sale_price = product.regular_price*0.5
  // }
  if (currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime)) {
    product.on_sale = true;
    product.sale_price = product.regular_price*0.5
  }

  
  return (
    <div className="product relative">
      {/* {product.name.toLowerCase().includes("full color") && (
          <img className="absolute left-0 top-0 z-50 batch" src={batch} alt=""/>
      )
      } */}
      {JSON.stringify(product?.on_sale && product?.stock_quantity > 0) ===
        "true" && (
        <div className="bg-accent absolute w-[40px] h-[40px] left-2 top-6 z-50 flex justify-center items-center rounded-full sm:w-[50px] sm:h-[50px] sm:left-4 sm:top-4">
          <p style={{ color: "#fff", fontFamily: "Roboto-Regular" }}>
            -
            {Math.floor(
              ((product.regular_price - product.sale_price) * 100) /
                product.regular_price
            )}
            %
          </p>
        </div>
      )}
      {product?.stock_quantity < 1 && (
        <div className="bg-[#cccccc] absolute w-[40px] h-[40px] left-2 top-6 z-50 flex justify-center items-center rounded-full sm:w-[50px] sm:h-[50px] sm:left-4 sm:top-4">
          <p style={{ color: "#fff", fontFamily: "Roboto-Regular" }}>
            Stock Out
          </p>
        </div>
      )}

      <div
        onMouseEnter={() => setMouseHover(true)}
        onMouseLeave={() => setMouseHover(false)}
        className="img-container cursor-pointer"
      >
        <div
          onClick={() => navigate(`/product/${product.slug}`)}
          className="img-div"
        >
          <img
            src={
              mouseHover
                ? product?.images[1]?.src
                  ? product?.images[1]?.src
                  : product?.images[0]?.src
                : product?.images[0]?.src
            }
            alt={product?.name}
          />
        </div>
        {/* The button to open modal */}
        {/* <button className="details-btn" onClick={handleModal}>
          QUICK VIEW
        </button> */}
        {/* <Modal
          handleModal={handleModal}
          handleBlurClose={handleBlurClose}
          product={product}
          dialogueRef={dialogueRef}
        ></Modal> */}
        <button
          onClick={() => handleAddToCart(product)}
          className={`add-btn ${
            product?.stock_quantity > 0
              ? "bg-black"
              : "pointer-events-none bg-[#cccccc]"
          }`}
        >
          ADD TO CART
        </button>
      </div>

      <div className="product-details">
        {/* ...........variations............... */}
        {product?.variations?.length > 1 && (
          <div
            className={`flex gap-4 mb-3 h-[40px] items-center w-[170px] md:w-[235px] lg:w-[170px] xl:w-[200px] 2xl:w-[265px] ${
              list ? "" : "mx-auto"
            }`}
          >
            {product?.variations.slice(0, 3)?.map((variation) => {
              let code = variation?.code;
              let style = { backgroundColor: code };
              return (
                <Link
                  to={`/product/${variation.product_slug}`}
                  className={`w-[30px] h-[30px] cursor-pointer rounded-full`}
                  style={style}
                >
                  {/* <p className="opacity-0 hover:opacity-60 bg-secondary text-primary px-2 absolute ml-[-50px]">
                    {variation.color}
                  </p> */}
                </Link>
              );
            })}
            {product.variations.length > 3 && (
              <Link to={`/product/${product.slug}`}>
                +{product.variations.length - 3}
              </Link>
            )}
          </div>
        )}
        <p className="limit-lines">{product?.name}</p>
        {window.innerWidth > 640 && (
          <div
            className={`text-[#f3c621] flex ${
              list ? "" : "sm:justify-center"
            } `}
          >
            {/* <StarRating rating={product.average_rating} size={20}></StarRating> */}
          </div>
        )}
        <p>
          {list ? (
            <div
              className="limit-lines"
              dangerouslySetInnerHTML={{ __html: product?.description }}
            />
          ) : (
            ""
          )}
        </p>
        <div className={`flex gap-2 ${list ? "" : "sm:justify-center"} `}>
          <p className="text-accent font-bold">
            {" "}
            {JSON.stringify(product?.on_sale) === "true"
              ? product?.sale_price
              : product?.regular_price}{" "}
            TK.
          </p>
          {JSON.stringify(product?.on_sale) === "true" && (
            <p
              style={{
                textDecorationLine: "line-through",
                textDecorationStyle: "solid",
                color: "#000",
                opacity: 0.5,
              }}
            >
              {`${product?.regular_price} TK.`}
            </p>
          )}
        </div>
        {/* <p className="text-accent font-bold">
          App price :{" "}
          {product?.on_sale == "true"
            ? Math.floor(product?.sale_price - product?.sale_price * 0.05)
            : Math.floor(
                product?.regular_price - product?.regular_price * 0.05
              )}{" "}
          TK.
        </p> */}
        <button
          onClick={() => handleAddToCart(product)}
          className={`list-btn mt-4`}
        >
          ADD TO CART
        </button>
      </div>
    </div>
  );
};

export default Product;
