import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";

const Analytics = () => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [orders, setOrders] = useState([]);
  const [netSales, setNetSales] = useState(0);
  const [update, setUpdate] = useState(true);
  // const { products } = useContext(ThemeContext);
  useEffect(() => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const startDate = new Date(
      new Date(from).setHours(0, 1, 0, 0)
    ).toISOString();
    const endDate = new Date(new Date(to).setHours(23, 59, 0, 0)).toISOString();

    if (update) {
      fetch(
        `https://flormar-web-server2.vercel.app/sortOrders?startDate=${startDate}&endDate=${endDate}`
      )
        .then((res) => res.json())
        .then((data) => {
          setOrders(data);
          setUpdate(false);
        });
      fetch("https://flormar-web-server2.vercel.app/orders/total")
        .then((res) => res.json())
        .then((data) => setNetSales(data.totalValue));
    }
  }, [update]);

  let totalSales = 0;
  orders.forEach((order) => {
    totalSales = parseInt(totalSales) + parseInt(order.total);
  });

  let totalProduct = 0;
  orders.forEach((order) => {
    order.items.map((item) => {
      totalProduct = parseInt(totalProduct) + parseInt(item.quantity);
    });
  });

  const sortedOrder = [];
  orders.map((order) => {
    order.items.map((item) => {
      const exists = sortedOrder.find((obj) => obj.id === item.product_id);
      if (!exists) {
        sortedOrder.push({
          name: item?.product_name,
          qty: item.quantity,
          total: item.total,
          id: item.product_id,
        });
      } else {
        exists.qty = exists.qty + item.quantity;
        exists.total = parseInt(exists.total) + parseInt(item.total);
      }
    });
  });
  return (
    <div className="p-[8%]">
      <div className="flex gap-10 items-center px-[2%]">
        <p>Date from :</p>
        <input
          className="border border-secondary my-2 px-2 py-1"
          type="date"
          value={from}
          onChange={(e) => setFrom(e.target.value)}
        ></input>
        <p>Date to :</p>
        <input
          className="border border-secondary my-2 px-2 py-1"
          type="date"
          value={to}
          onChange={(e) => setTo(e.target.value)}
        ></input>
        <button
          onClick={() => setUpdate(true)}
          className="bg-accent text-primary px-2 py-1 bor
        der border-accent hover:bg-secondary hover:border-secondary ease-in-out duration-200"
        >
          Update
        </button>
      </div>
      <div className="mt-[100px] flex justify-center gap-10 items-center px-[2%]">
        <div className="border border-secondary w-[400px] px-10 py-6 border-dotted">
          <p className="text-xl">Total sales</p>
          <p className="mt-2">TK. {totalSales}</p>
        </div>
        <div className="border border-secondary w-[400px] px-10 py-6 border-dotted">
          <p className="text-xl">Lifetime Sale</p>
          <p className="mt-2">TK. {netSales} </p>
        </div>
        <div className="border border-secondary w-[400px] px-10 py-6 border-dotted">
          <p className="text-xl">Orders</p>
          <p className="mt-2">{orders?.length}</p>
        </div>
        <div className="border border-secondary w-[400px] px-10 py-6 border-dotted">
          <p className="text-xl">Product sold</p>
          <p className="mt-2">{totalProduct}</p>
        </div>
      </div>
      <div className="mt-10">
        <p className="text-xl px-[2%]">Sold products</p>
        <table className=" product-table">
          {/* head */}
          <thead className="bg-accent text-primary">
            <tr>
              <th></th>
              <th>Products</th>
              <th>Total</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {/*................table row............... */}
            {sortedOrder.map((order) => {
              return (
                <tr className="">
                  <th></th>
                  <td>
                    <p>{order?.name}</p>
                  </td>
                  <td>TK. {order.total}</td>
                  <td>
                    <p>{order?.qty}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {/* foot */}
          <tfoot className="bg-accent text-primary">
            <tr>
              <th></th>
              <th>Products</th>
              <th>Total</th>
              <th>Quantity</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Analytics;
